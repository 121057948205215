<nav class="navbar navbar-expand-lg navbar-absolute fixed-top">
    <div class="container-fluid">
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-around" style="margin: 0px 7%" id="navigation">
            <!-- <form class="navbar-form">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form> -->
            <div style="width: 85%">
                <img style="width:200px; margin-left:30px;" src="/assets/svg/new/lndr-payroll.svg" />
            </div>
            <div style="max-height: 50px; text-transform: none !important; margin-right:40px;">
                <div class="col-md-7 pt-1">
                    <div style="width: 100%; margin-right:10%; text-align: right;">
                        <div class="row">
                            <div class="col-md-12 p-0 loading_icon" class="" style="font-size: larger;">

                                <!-- <img class="icon_margin" style="height: 45px;" src="/assets/img/uloans/profile.svg"> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="navbar-nav">

                <li class="nav-item dropdown" style="width: 60px;">
                    <a class="nav-link" id="navbarDropDownProfile" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                        <img class="icon_margin" style="height: 45px;" src="/assets/img/uloans/profile.svg">
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" style="width: 100%; right: -13px; top: 87px;" aria-labelledby="navbarDropDownProfile">
                        <a (click)="logout()">
                            <div class="card bg-blue m-3 logout-card">
                                <div class="row m-2 logout-row">
                                    <div class="pt-2">
                                        <span style="font-size: large; font-weight: 400;">Logout
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!--

<nav class="navbar navbar-transparent navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-header">
            <button mat-raised-button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">dashboard</i>
                        <p class="hidden-lg hidden-md">Dashboard</p>
                    </a>
                </li>
                <li class="dropdown">
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <p class="hidden-lg hidden-md">Notifications</p>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="javascript:void(0)">Mike John responded to your email</a></li>
                        <li><a href="javascript:void(0)">You have 5 new tasks</a></li>
                        <li><a href="javascript:void(0)">You're now friend with Andrew</a></li>
                        <li><a href="javascript:void(0)">Another Notification</a></li>
                        <li><a href="javascript:void(0)">Another One</a></li>
                    </ul>
                </li>
                <li>
                    <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                       <i class="material-icons">person</i>
                       <p class="hidden-lg hidden-md">Profile</p>
                    </a>
                </li>
            </ul>

            <form class="navbar-form navbar-right" role="search">
                <div class="form-group form-black is-empty">
                    <input type="text" class="form-control" placeholder="Search">
                    <span class="material-input"></span>
                </div>
                <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i><div class="ripple-container"></div>
                </button>
            </form>
        </div>
    </div>
</nav> -->