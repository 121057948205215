import { ApplicationRepo } from './repos/ApplicationRepo';
import { Injectable } from '@angular/core';


import { PasswordDetails, UserCredentials } from './models/user-credentials';
import { Approval, Decline, LoanApplication } from './models/loan-classes';
import {
    EmployerDetails,
    Report,
    BankingDetails,
    SaveClientForCallback,
    Consent,
    Acknowledgments,
    FindClient
} from './models/broker-classes';
import { ThemeService } from 'ng2-charts';

class batchFileRequest {
    BatchFile: string;
    token: string;
    staffId: number
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    clientId: number;
    constructor(public appRepo: ApplicationRepo) {
    }

    // API: GET 

    public getProducts(staffId: number) {
        return this.appRepo.getProducts(staffId);
    }
    public getLoansToApprove(staffId: number) {
        return this.appRepo.getLoansToApprove(staffId);
    }

    public getNotes(loanId: number) {
        return this.appRepo.getNotes(loanId);
    }

    public getInitiations() {
        return this.appRepo.getInitiations();
    }

    public getCountries() {
        return this.appRepo.getCountriesList();
    }

    public getStaff() {
        return this.appRepo.getStaffList();
    }

    public getBanks() {
        return this.appRepo.getBanks();
    }

    public getAccountTypes() {
        return this.appRepo.getAccountTypes();
    }

    public getClientsByStaffId() {
        return this.appRepo.getClientsByStaffId();
    }

    public generatePrevetReport(reportRequest: Report) {
        return this.appRepo.generatePrevetReport(reportRequest);
    }

    public generateProcessReport(reportRequest: Report) {
        return this.appRepo.generateProcessReport(reportRequest);
    }

    public generateApprovalReport(reportRequest: Report) {
        return this.appRepo.generateApprovalReport(reportRequest);
    }

    public getDeclinedLetter(reportRequest: Report) {
        return this.appRepo.getDeclinedLetter(reportRequest);
    }

    public getLoans(clientId: number) {
        return this.appRepo.getLoansList(clientId);
    }

    public getUser(userId: number) {
        return this.appRepo.GetUser(userId);
    }

    // API: POST
    public approveLoan(approval: Approval) {
        return this.appRepo.approveLoan(approval);
    }

    public declineLoan(decline: Decline) {
        return this.appRepo.declineLoan(decline);
    }

    public submitLoanApplication(loanApp: LoanApplication) {
        return this.appRepo.submitLoanApplication(loanApp);
    }

    public login(credentials: UserCredentials) {
        return this.appRepo.Login(credentials);
    }

    public saveClient(client: SaveClientForCallback) {
        return this.appRepo.uploadClientFromPortal(client);
    }

    public getClient(clientIdPassport: string) {
        return this.appRepo.getClientByIdPassport(clientIdPassport);
    }

    public getBankDetails(clientId: string) {
        return this.appRepo.getBankDetails(clientId);
    }

    public getEmployerDetails(clientId: string) {
        return this.appRepo.getEmployerDetails(clientId);
    }

    public getConsent(clientId: string) {
        return this.appRepo.getConsent(clientId);
    }

    public getAcknowledgements(clientId: string) {
        return this.appRepo.getAcknowledgements(clientId);
    }

    public saveConsent(consent: Consent) {
        return this.appRepo.saveConsent(consent);
    }

    public doCreditCheck(clientId: number) {
        return this.appRepo.doCreditCheck(clientId);
    }

    public getCreditBureauAssessment(clientId: number) {
        return this.appRepo.getCreditBureauAssessment(clientId);
    }

    public saveBankingDetails(bankDetails: BankingDetails) {
        return this.appRepo.saveBankDetails(bankDetails);
    }

    public saveAcknowledgments(acknowledgments: Acknowledgments) {
        return this.appRepo.saveAcknowledgments(acknowledgments);
    }

    public saveEmployerDetails(empDetails: EmployerDetails) {
        return this.appRepo.saveEmployerDetails(empDetails);
    }

    public uploadIdDocument(file: FormData) {
        return this.appRepo.uploadIdDocument(file);
    }

    public uploadBatchDocument(file: batchFileRequest) {
        return this.appRepo.uploadBatchDocument(file);
    }

    public uploadDocument(file: FormData) {
        return this.appRepo.uploadDocument(file);
    }

    public sendUlEmail(staffId: number, clientId: number) {
        return this.appRepo.sendUlEmail(staffId, clientId);
    }

    public getHistoryBatchResults(history_date: string, startTime: string, endTime: string) {
        return this.appRepo.getHistoryBatchResults(history_date, startTime, endTime);
    }

    public renewPassword(userId: number, renewedPassword: PasswordDetails) {
        return this.appRepo.renewPassword(userId, renewedPassword);
    }
}
