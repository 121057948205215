import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';

import { ApiService } from 'app/ts/ApiService';
import { Consent, SaveClientForCallback } from 'app/ts/models/broker-classes';
import { Countries, DateModel, FindClient } from 'app/ts/models/broker-interfaces';
import { ActivatedRoute } from '@angular/router';
import { IdentificationTypeService } from 'app/ts/services/identification-type.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss', '../../forms.shared.scss']
})
export class PersonalDetailsComponent implements OnInit {
  idTypeValue: number = 0;
  clientId: number = 0;
  idtypes: string[] = ['RSA ID Number', 'Passport Number'];
  countries: Countries[];
  selectedCountries: Countries[];
  client = {} as SaveClientForCallback;
  clientFind = {} as FindClient;
  consent = {} as Consent;
  passed: boolean;
  error: string;
  accountHolder: string;
  ngDateOfBirth: Date;
  ngDateOfIssue: Date;
  ngExpiryDate: Date;

  isPassport$ = this._identificationType.isPassport;

  dateModel: DateModel = {
    ngDateOfBirth: new Date(),
    ngDateOfIssue: new Date(),
    ngExpiryDate: new Date(),
  };


  title = 'detect-route-change';
  currentRoute: string;

  radio_list = [
    {
      id: '1',
      name: 'radio_id',
      value: 0,
      label: 'RSA ID Number',
      disabled: false,
      checked: true,
    }, {
      id: '2',
      name: 'radio_passport',
      value: 1,
      label: 'Passport',
      disabled: false,
      checked: false,
    }
  ];

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private _identificationType: IdentificationTypeService
  ) {

    this.setPersonalDetailsForm();

    this._identificationType.isPassport$.subscribe((x: null) => {
      if (x !== undefined || x !== null) {
        if (this.isPassport$ != x) {
          this.isPassport$ = x;
        }
      }
    });

  }

  personalDetails: FormGroup;
  canProceed: boolean = false;

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();

  ngOnInit() {
    this.consent = {} as Consent;

    this.api.getCountries().subscribe((data: Countries[]) => {
      this.countries = data;
      this.selectedCountries = this.countries;
    });

    this.populateSearchClient();
  }

  ngAfterViewChecked(): void {
    this.paramsChanged();
  }

  paramsChanged() {
    this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        sessionStorage.setItem('searchClientID', this.clientId.toString());

        if (this.clientId != 0) {
          this.populateSearchClient();
        } else {
          this.setPersonalDetailsForm();
        }

      }
    });
  }

  populateSearchClient() {
    this.paramsChanged();

    if (this.clientId != 0) {
      this.clientFind = JSON.parse(sessionStorage.getItem('ClientResult'));
      this.consent = JSON.parse(sessionStorage.getItem('ConsentResult'));

      //Show passport or ID fields
      this.radio_list.forEach(r => {

        if (this.clientFind.isPassport) {
          if (r.name == 'radio_passport') {
            r.checked = true;
          }
          if (r.name == 'radio_id') {
            r.checked = false;
          }
          if (r.id == '2') {
            this.radioSelect(Number(r.id) - 1);
          }
        }
        else {
          if (r.name == 'radio_passport') {
            r.checked = false;
          }
          if (r.name == 'radio_id') {
            r.checked = true;
          }
          if (r.id == '1') {
            this.radioSelect(Number(r.id) - 1)
          }

        }

      });

      this.client.name = this.clientFind.FirstName;
      this.client.surname = this.clientFind.LastName;
      this.client.idnumber = this.clientFind.ExternalID;
      this.client.cellnumber = this.clientFind.MobileNumber;
      this.client.country = this.clientFind.Country;
      this.client.dateOfBirth = this.clientFind.DateOfBirth;
      this.client.dateOfIssue = this.clientFind.DateOfIssue;
      this.client.email = this.clientFind.Email;
      this.client.expiryDate = this.clientFind.ExpiryDate;
      this.client.isPassport = this.clientFind.isPassport;
      this.client.staffId = this.clientFind.StaffId;
      this.client.loanStatusId = this.clientFind.LoanStatusId;

      this.personalDetails.controls.idNumber.patchValue(this.client.idnumber);
      this.personalDetails.controls.name.patchValue(this.client.name);
      this.personalDetails.controls.surname.patchValue(this.client.surname);
      this.personalDetails.controls.email.patchValue(this.client.email);
      this.personalDetails.controls.mobileNumber.patchValue(this.client.cellnumber);
      this.personalDetails.controls.country.patchValue(this.client.country);

      this.dateModel.ngDateOfBirth = new Date(this.client.dateOfBirth);
      this.dateModel.ngDateOfIssue = new Date(this.client.dateOfIssue);
      this.dateModel.ngExpiryDate = new Date(this.client.expiryDate);

      this.personalDetails.controls.popiaConsent.setValue(this.consent.popi);
      this.personalDetails.controls.marketingConsent.setValue(this.consent.marketingEmail);

      sessionStorage.setItem('clientId', this.clientFind.ClientId.toString());
      sessionStorage.setItem('loanStatusId', this.client.loanStatusId.toString());

      if (this.client.loanStatusId == 300) {
        this.personalDetails.disable();
      }
      else {
        this.personalDetails.enable();
      }

    }
    else {
      sessionStorage.removeItem('ClientResult');
      sessionStorage.removeItem('ConsentResult');

      this.personalDetails.controls.idNumber.patchValue('');
      this.personalDetails.controls.name.patchValue('');
      this.personalDetails.controls.surname.patchValue('');
      this.personalDetails.controls.email.patchValue('');
      this.personalDetails.controls.mobileNumber.patchValue('');
      this.personalDetails.controls.country.patchValue('');

      this.personalDetails.controls.popiaConsent.setValue(false);
      this.personalDetails.controls.marketingConsent.setValue(false);
    }

  }

  setPersonalDetailsForm() {
    sessionStorage.setItem('clientId', "0");
    this.personalDetails = this.formBuilder.group({
      idNumber: ['', [Validators.required, Validators.minLength(13)]],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      country: [''],
      dateOfBirth: [''],
      dateOfIssue: [''],
      expiryDate: [''],
      popiaConsent: [false, [Validators.requiredTrue]],
      marketingConsent: [false],
    });

    this.radio_list.forEach(r => {
      if (r.name == 'radio_passport') {
        r.checked = false;
      }

      if (r.name == 'radio_id') {
        r.checked = true;
      }

      if (r.id == '1') {
        this.radioSelect(Number(r.id) - 1)
      }
    });
  }

  radioSelect(value: number) {
    this.idTypeValue = value;
    if (value == 0) {
      this.client.isPassport = false;
      this.personalDetails.get('dateOfIssue').clearValidators();
      this.personalDetails.get('expiryDate').clearValidators();
      this.personalDetails.get('dateOfBirth').clearValidators();
      this.personalDetails.get('expiryDate').clearValidators();
      this.personalDetails.get('country').clearValidators();
      this.personalDetails.get('idNumber').setValidators([Validators.required, Validators.minLength(13)]);
      this.personalDetails.get('idNumber').setErrors({ 'idNumber': true });
    }

    if (value == 1) {
      this.client.isPassport = true;
      this.personalDetails.get('dateOfIssue').setValidators(Validators.required);
      this.personalDetails.get('expiryDate').setValidators(Validators.required);
      this.personalDetails.get('dateOfBirth').setValidators(Validators.required);
      this.personalDetails.get('idNumber').setValidators([Validators.required]);
      this.personalDetails.get('idNumber').setErrors({ 'idNumber': true });
    }

    this._identificationType.updateIdentificationType(this.client.isPassport);
  }

  checkAge(idNumber: string): ValidatorFn {
    return (control: AbstractControl) => {
      var s = idNumber.substring(0, 6);

      return control?.value === control?.parent?.controls[idNumber].value ? null : { isMatching: true };
    }
  }

  save(): void {
    if (this.personalDetails.valid) {

      this.client.isPassport = this.isPassport$;

      if (!this.client.isPassport) {
        this.client.country = 20;
      }

      this.client.staffId = Number(sessionStorage.getItem('staffId'));
      this.client.idnumber = this.personalDetails.controls['idNumber'].value;
      this.client.name = this.personalDetails.controls['name'].value;
      this.client.surname = this.personalDetails.controls['surname'].value;
      this.client.email = this.personalDetails.controls['email'].value;
      this.client.cellnumber = this.personalDetails.controls['mobileNumber'].value;
      this.client.dateOfBirth = this.personalDetails.controls['dateOfBirth'].value;
      this.client.dateOfIssue = this.personalDetails.controls['dateOfIssue'].value;
      this.client.expiryDate = this.personalDetails.controls['expiryDate'].value;
      this.client.country = this.personalDetails.controls['country'].value;

      var url = window.location.href;

      let subdomain = this.getSubdomain(window.location.href);

      if (url.includes('localhost')) {
        subdomain = "uat-payroll";
      }
      
      this.client.subDomain = subdomain;

      this.api.saveClient(this.client).subscribe((data: any) => {

        if (data.message == "Loan has been submitted and is awaiting approval") {

          let clientId = data.id;

          this.accountHolder = `${this.client.name} ${this.client.surname}`;
          sessionStorage.setItem('accountHolder', this.accountHolder);
          sessionStorage.setItem('clientId', data.id.toString());
          sessionStorage.setItem('username', `${this.client.surname.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}${this.client.name.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}`);

          if (this.consent == null) {
            this.consent = new Consent();
          }

          this.consent.clientId = clientId
          this.consent.loanId = 0;
          this.consent.popi = this.personalDetails.controls['popiaConsent'].value;
          this.consent.marketingMobile = this.personalDetails.controls['marketingConsent'].value;

          this.api.saveConsent(this.consent).subscribe((consent: any) => {
            if (consent.message == "Success") {
              this.api.doCreditCheck(clientId).subscribe((check: any) => {
                sessionStorage.setItem("creditCheckResult", JSON.stringify(check));
                this.openSnackBar('Client Saved', 'success-snackbar');
              })

              this.updateStepEvent.emit();

            } else {
              this.openSnackBar(consent.message, 'error-snackbar');
              this.client = new SaveClientForCallback();
              this.consent = {} as Consent;
            }
          })
        } else {
          this.openSnackBar(data.message, 'error-snackbar');
          this.client = new SaveClientForCallback();
          this.consent = {} as Consent;
        }
      });
    }
  }

  onKey(value: string) {
    this.selectedCountries = this.search(value);
  }

  search(value: string) {

    return this.countries.filter(item =>
      Object.keys(item).some(k => item[k] != null &&
        item[k].toString().toLowerCase()
          .includes(value.toLowerCase()))
    );
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  change(event: { value: number; }) {
    this.client = new SaveClientForCallback();
  }

  back(): void {
    this.previousStepEvent.emit();
  }

  checked(event: MatCheckboxChange) {
    this.canProceed = this.personalDetails.controls["popiaConsent"].value;
  }

  getSubdomain(url: string): string | null {
    try {
      const parsedUrl = new URL(url);
      const parts = parsedUrl.hostname.split('.');
      return parts.length >= 3 ? parts[0] : null;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }
}
